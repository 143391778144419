import * as React from 'react';

import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Highlight, PlaceholderCard } from '@Components/highlight/Highlight';
import WhoToFollowCard from './WhoToFollowCard';
import { withTranslation } from 'react-i18next';

export class WhoToFollow extends React.Component {
  componentDidMount() {
    if (!this.props.creators?.trending?.["All Creators"]){
      this.props.getAccounts(`$expand=Profile&$top=32`, 'trending', 'All Creators');
    }
  }

  render() {
    const recommendedAccountIds = this.props.creators?.trending?.["All Creators"];
    const { history, unfollowAccount, followAccount, openModal, account, whoToFollow, allAccounts, t } = this.props;
    return (
      <Highlight link={{ text: t('public.viewAllSugg'), to: '/discover/creators' }} title={t('public.whoToFollow')}>
        {recommendedAccountIds?.length
          ? recommendedAccountIds?.slice(0, 3).map((accountId) => {
              const recommendedAccount = allAccounts?.[accountId];
              return (
                <WhoToFollowCard
                  key={'recommended-account' + recommendedAccount.id}
                  account={recommendedAccount}
                  followAccount={followAccount}
                  history={history}
                  openModal={openModal}
                  ownerAccount={account}
                  unfollowAccount={unfollowAccount}
                />
              );
            })
          : [1, 2, 3].map((i) => <PlaceholderCard key={'recommended' + i} borderRadius={60} />)}
      </Highlight>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        ...state.feed,
        ...state.user.account,
        ...state.auth,
        ...state.profile,
        ...state.collectible,
        ...state.account,
        ...state.cache,
        ...state.discover,
      };
    },
    {
      ...AccountActions,
      ...ModalActions,
    },
    null,
    { forwardRef: true },
  )(withTranslation()(WhoToFollow)),
);
