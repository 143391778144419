import * as React from 'react';

import ProfileAvatar from '@Components/profile/ProfileAvatar';
import { AccountActions } from '@Actions/AccountActions';
import { connect } from 'react-redux';
import Button from '@Components/form/Button';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { ModalActions } from '@Actions/ModalActions';
import Truncate from 'react-truncate';
import AnnotatedContent from '@Components/shared/AnnotatedContent';
import millify from 'millify';
import { debounce } from 'debounce';
import { InlineLoader } from '@Components/shared/Spinner';
import { withTranslation } from 'react-i18next';

class ProfileDescriberCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFollow: false,
      openTooltip: false,
      showAccountData: false,
      mouseOverFollowButton: false,
    };
  }

  followAccount() {
    if (this.props.account?.id !== this.props.mentionProfile?.id) {
      this.props.followAccount(this.props.mentionProfile?.id).then((result) => {
        if (!result.error) this.setState({ isFollow: true });
      });
    }
  }

  unfollowAccount() {
    if (this.props.account?.id !== this.props.mentionProfile?.id) {
      this.setState({ open: false }, () => {
        this.props.openModal('VerifyUnfollowModal', {
          backdrop: true,
          closeOnOutsideClick: true,
          followerData: this.props.mentionProfile,
        });
      });
    }
  }

  handleOpenTooltip() {
    if (!this.state.openTooltip) {
      this.props.getAccountByMention(this.props.slug).then((result) => {
        if (this.state.openTooltip) {
          this.setState({ showAccountData: true, isFollow: result?.payload?.actorFollowee });
        }
      });
    }

    this.setState({ openTooltip: true });
  }

  debouncedOpenTooltip = debounce(this.handleOpenTooltip, 250);

  handleCloseTooltip() {
    this.debouncedOpenTooltip.clear();
    this.setState({ openTooltip: false, showAccountData: false });
  }

  render() {
    const { t } = this.props;
    let isCurrentAccount = this.props.account && this.props.account.id === this.props.mentionProfile?.id;
    const profileComponent = (
      <div
        className={'profile-describer-card' + (this.props.mentionProfile?.actorBlocked ? ' block' : '')}
        style={{ width: '320px' }}>
        {!this.state.showAccountData ? (
          <div className="loader">
            <InlineLoader />
          </div>
        ) : (
          <div className="content">
            <div className="top-area">
              <div className="account-profile">
                <ProfileAvatar profileData={this.props.mentionProfile} width={80} height={80} />
                {this.props.account?.id !== this.props.mentionProfile?.id ? (
                  <div className="button-wrap">
                    {this.props.mentionProfile?.actorBlocked ? (
                      <Button
                        className="button mb-2"
                        text={this.state.mouseOverFollowButton ? t('public.unblockButton') : t('public.blockedButton')}
                        style={{ backgroundColor: '#ff0000', color: 'white' }}
                        fullSize="true"
                        disabled={this.props.loadingFollowState}
                        onMouseOver={() => {
                          this.setState({ mouseOverFollowButton: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ mouseOverFollowButton: false });
                        }}
                        onClick={() => {
                          this.props.unblockAccount(this.props.mentionProfile?.id).then((res) => {
                            if (!res.error) {
                              this.setState({ open: false }, () => this.handleOpen());
                            }
                          });
                        }}
                      />
                    ) : isCurrentAccount ? null : (
                      <Button
                        className="mr-2"
                        text={
                          this.state.isFollow
                            ? this.state.mouseOverFollowButton
                              ? t('public.unfollowButton')
                              : t('public.following')
                            : t('public.follow')
                        }
                        type={this.state.isFollow ? 'follow' : 'primary'}
                        fullSize
                        onMouseOver={() => {
                          this.setState({ mouseOverFollowButton: true });
                        }}
                        onMouseOut={() => {
                          this.setState({ mouseOverFollowButton: false });
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (!this.state.isFollow) this.followAccount();
                          else this.unfollowAccount();
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <Link to={'/' + this.props.mentionProfile?.slug} className="name mt-2">
                {this.props.mentionProfile?.profile?.name}
              </Link>
              <Link to={'/' + this.props.mentionProfile?.slug} className="slug mb-3">
                @{this.props.mentionProfile?.slug}
              </Link>
              {this.props.mentionProfile?.actorBlocked ? null : (
                <>
                  <div className="category">{t(`public.${this.props.mentionProfile?.category?.name}`)}</div>
                  <div className="about mt-1">
                    <Truncate trimWhitespace lines={3} ellipsis={<span>...</span>}>
                      {this.props.mentionProfile?.profile?.about && (
                        <AnnotatedContent content={this.props.mentionProfile?.profile?.about} />
                      )}
                    </Truncate>
                  </div>
                </>
              )}
            </div>
            <div className="bottom-area">
              <div className="follow-count mt-2">
                <Link to={'/' + this.props.mentionProfile?.slug + '/following'} className="mr-2">
                  {millify(this.props.mentionProfile?.followeesCount || 0)} <span className="follow">{t('public.following')}</span>
                </Link>
                <Link to={'/' + this.props.mentionProfile?.slug + '/followers'}>
                  {millify(this.props.mentionProfile?.followersCount || 0)} <span className="follow">{t('public.followers')}</span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
    return (
      <Tooltip
        placement={this.props.placement ? this.props.placement : 'bottom'}
        open={this.state.openTooltip}
        onOpen={() => this.debouncedOpenTooltip()}
        onClose={() => this.handleCloseTooltip()}
        classes={{ popper: 'tooltip' }}
        interactive
        title={this.props.component ? this.props.component : profileComponent}>
        {this.props.children}
      </Tooltip>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.profile, ...state.user.account };
  },
  { ...AccountActions, ...ModalActions },
)(withTranslation()(ProfileDescriberCard));
export default component;
