import * as React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@Components/shared/Icon';
import { URL_REGEX } from '@AppVariables';

export default class SocialButtons extends React.Component {
  constructor(props) {
    // this.props => { accountProfile }
    super(props);
  }
  componentDidMount() {}

  getClassName() {
    let cname = 'social-buttons';
    if (this.props.className) cname += ' ' + this.props.className;

    return cname;
  }

  prefixValue = {
    website: 'https://',
    discord: 'https://www.discord.com/users/',
    youTube: 'www.youtube.com/',
    instagram: 'https://www.instagram.com/',
    twitter: 'https://www.twitter.com/',
    facebook: 'https://www.facebook.com/',
    twitch: 'https://www.twitch.tv/',
    tikTok: 'https://www.tiktok.com/@',
  };

  getLinkPrefix = (profile) => {
    let newProfile = { ...profile };

    var regex = new RegExp(URL_REGEX);
    for (const [key, value] of Object.entries(this.prefixValue)) {
      if (newProfile[key] && !newProfile[key].match(regex)) {
        newProfile[key] = value + newProfile[key];
      }
    }

    return newProfile;
  };

  render() {
    if (!this.props.accountProfile) {
      return null;
    }

    let profileAccount = this.getLinkPrefix(this.props.accountProfile);

    return (
      <div className={this.getClassName()}>
        {profileAccount && profileAccount.website ? (
          <a href={profileAccount.website} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fas fa-globe-americas" />
          </a>
        ) : null}
        {profileAccount && profileAccount.twitter ? (
          <a href={profileAccount.twitter} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-twitter" />
          </a>
        ) : null}
        {profileAccount && profileAccount.facebook ? (
          <a href={profileAccount.facebook} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-facebook" />
          </a>
        ) : null}
        {profileAccount && profileAccount.instagram ? (
          <a href={profileAccount.instagram} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-instagram" />
          </a>
        ) : null}
        {profileAccount && profileAccount.discord ? (
          <a href={profileAccount.discord} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-discord" />
          </a>
        ) : null}
        {profileAccount && profileAccount.youTube ? (
          <a href={profileAccount.youTube} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-youtube" />
          </a>
        ) : null}
        {profileAccount && profileAccount.linkedin ? (
          <a href={profileAccount.linkedin} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-linkedin" />
          </a>
        ) : null}
        {profileAccount && profileAccount.twitch ? (
          <a href={profileAccount.twitch} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-twitch" />
          </a>
        ) : null}
        {profileAccount && profileAccount.tikTok ? (
          <a href={profileAccount.tikTok} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-tiktok" />
          </a>
        ) : null}
        {profileAccount && profileAccount.snapchat ? (
          <a href={profileAccount.snapchat} target="_blank" rel="noreferrer nofollow">
            <Icon type="fontawesome" src="fab fa-snapchat" />
          </a>
        ) : null}
      </div>
    );
  }
}
