import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import copy from 'copy-to-clipboard';

import Button from '@Components/form/Button';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import millify from 'millify';
import { buildCreatorCategoryLink, getProfileNameOrShortPublicAddress } from '@Utils';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '@Components/shared/Icon';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import ToastActions from '@Actions/ToastActions';

class FollowerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverFollowButton: false,
    };
    this.optionsDropdownRef = React.createRef();
  }

  followAccount() {
    if (this.props.account?.id !== this.props.followerData.id) {
      this.props.followAccount(this.props.followerData.id);
    } else {
    }
  }

  unfollowAccount() {
    if (this.props.account?.id !== this.props.followerData.id) {
      this.props.openModal('VerifyUnfollowModal', {
        backdrop: true,
        closeOnOutsideClick: true,
        followerData: this.props.followerData,
      });
    } else {
    }
  }

  @bind
  visitProfile() {
    this.props.history.push('/' + this.props.followerData.slug);
  }

  removeFollower = () => {
    const { followerData: { id }, t } = this.props;

    if (id) {
      this.props.removeFollower(id).then(() => {
        this.props.toastSuccess(t('public.succesfullyRemoved'));
      });
    }
  }

  copyToClipboard = () => {
    const { followerData: { slug }, t } = this.props;
    if (window.location.origin && slug) {
      copy(`${window.location.origin}/${slug}`);
      this.props.toastSuccess(t('public.copied'));
    }
  };

  render() {
    const { account, followerData, t } = this.props;
    const isCurrentAccount = account && account.id === followerData?.account?.id;

    return (
      <div className={'follower-card ' + (this.props.active ? 'active' : ' ')}>
        <div
          className="container-left"
          onClick={(e) => {
            e.stopPropagation();
            this.visitProfile();
          }}>
          <div className="profile-image">
            <ProfileAvatar imageLink={this.props.followerData?.profile?.imageUrl} width={60} height={60} />
          </div>
          <div className="follower-info ml-2">
            <div className="name-container">
              <div className="name">
                {getProfileNameOrShortPublicAddress(
                  this.props.followerData.profile.name || this.props.followerData.slug,
                )}
              </div>
              <div className="slug">@{this.props.followerData?.slug}</div>
            </div>
            <Link
              onClick={(e) => e.stopPropagation()}
              to={buildCreatorCategoryLink(this.props.followerData?.category?.name)}
              className="category">
              {t(`public.${this.props.followerData?.category?.name}`)}{' '}
              {this.props.followerData.actorFollower ? (
                <span className="actor-follow-state ml-1">{t('public.followsYou')}</span>
              ) : null}
            </Link>
            <div className="followers">
              {millify(this.props.followerData.followeesCount)} {t('public.following')}{' '}
              {millify(this.props.followerData.followersCount)} {t('public.followers')}
            </div>
          </div>
        </div>
        {!this.props.account || this.props.account.id !== this.props.followerData.id ? (
          <div className="container-right">
            <Button
              className="mr-2"
              text={
                this.props.followerData.actorFollowee
                  ? this.state.mouseOverFollowButton
                    ? t('public.unfollowButton')
                    : t('public.following')
                  : t('public.follow')
              }
              type={this.props.followerData.actorFollowee ? 'follow' : 'primary'}
              fullSize
              onMouseOver={(e) => {
                this.setState({ mouseOverFollowButton: true });
              }}
              onMouseOut={(e) => {
                this.setState({ mouseOverFollowButton: false });
              }}
              onClick={(e) => {
                e.stopPropagation();

                if (!this.props.followerData.actorFollowee) this.followAccount();
                else this.unfollowAccount();
              }}
            />
            <span
              className="collectible-more"
              id={'options-button' + this.props.followerData?.id}
              onClick={(e) => {
                e.stopPropagation();
                this.optionsDropdownRef.current.open();
              }}>
              <Icon type="fontawesome" src="fas fa-ellipsis-h" />
            </span>
          </div>
        ) : null}
        <ContextDropdown transformOriginV="top" anchorEl={'options-button' + this.props.followerData?.id} ref={this.optionsDropdownRef}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.copyToClipboard();
              this.optionsDropdownRef.current.close();
            }}>
              {t('public.copyLinkToProfile')}
          </div>{' '}
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.props.openModal('ShareSocialAccountModal', {
                backdrop: true,
                closeOnOutsideClick: true,
                link: '/' + this.props.followerData.slug,
                mailBody: t('public.shareCollectibleMailBody'),
              });
              this.optionsDropdownRef.current.close();
            }}>
            {t('public.shareProfileVia')}
          </div>
          {!isCurrentAccount && this.props.followerData.actorFollower && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.removeFollower();
                this.optionsDropdownRef.current.close();
              }}>
              {t('public.removeButton')}  @
              {this.props.followerData?.slug}
            </div>
          )}
          {!isCurrentAccount && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.muteAccount(this.props.followerData.id).then((res) => {
                  if (!res.error) {
                    this.props.toastSuccess(t('public.muteAccount'));
                  } else {
                    this.props.toastError(t('public.error'));
                  }
                });
                this.optionsDropdownRef.current.close();
              }}>
              {t('public.mute')} @{this.props.followerData.slug}
            </div>
          )}
          {!isCurrentAccount && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.openModal('BlockWarningModal', {
                  onOutsideClick: true,
                  closeOnOutsideClick: true,
                  backdrop: true,
                  callback: () => {
                    this.props.blockAccount(this.props.followerData.id);
                    this.props.closeModal();
                  },
                  profileAccount: this.props.followerData,
                });
                this.optionsDropdownRef.current.close();
              }}>
              {t('public.blockButton')} @{this.props.followerData.slug}
            </div>
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.props.openModal('ReportModal', {
                onOutsideClick: true,
                closeOnOutsideClick: true,
                backdrop: true,
                targetAccountId: this.props.followerData.id,
              });
              this.optionsDropdownRef.current.close();
            }}>
            {t('public.reportButton')} @{this.props.followerData.slug}
          </div>
        </ContextDropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.notifications,
  ...state.profile,
});

export default connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...ToastActions })(
  withRouter(withTranslation()(FollowerCard)),
);
