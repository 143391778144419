import * as React from 'react';

import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Highlight, PlaceholderCard } from '@Components/highlight/Highlight';
import { HashtagActions } from '@Actions/HashtagActions';
import FollowHashtagsCard from './FollowHashtagsCard';
import { withTranslation } from 'react-i18next';

export class FollowHashtags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hashtags: null,
    };
  }

  componentDidMount() {
    const { getHashtags } = this.props;

    getHashtags('$filter=actorFollows eq false&$top=3&$skip=30').then((res) => {
      this.setState({
        hashtags: res.payload.value,
      });
    });
  }
  componentDidMount() {
    const { getRecommendedHashtags, followHashtags } = this.props;

    if (followHashtags.length === 0) {
      getRecommendedHashtags('$filter=actorFollows eq false&$top=3');
    }
  }

  render() {
    const { history, unfollowHashtag, followHashtag, openModal, getHashtag, followHashtags, allHashtags, t } =
      this.props;
    return (
      <Highlight link={{ text: t('public.viewAllSugg'), to: '/discover/hashtags' }} title={t('public.followHashtag')}>
        {followHashtags.length
          ? followHashtags.map((hashtagTag) => {
              const hashtag = allHashtags[hashtagTag];
              return (
                <FollowHashtagsCard
                  key={'hashtag' + hashtag.tag}
                  followHashtag={followHashtag}
                  getHashtag={getHashtag}
                  hashtag={hashtag}
                  history={history}
                  openModal={openModal}
                  unfollowHashtag={unfollowHashtag}
                />
              );
            })
          : [1, 2, 3].map((i) => <PlaceholderCard key={'recommended' + i} borderRadius={60} />)}
      </Highlight>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        ...state.feed,
        ...state.user.account,
        ...state.auth,
        ...state.profile,
        ...state.collectible,
        ...state.account,
        ...state.cache,
      };
    },
    {
      ...ModalActions,
      ...AccountActions,
      ...HashtagActions,
    },
    null,
    { forwardRef: true },
  )(withTranslation()(FollowHashtags)),
);
