import * as React from 'react';
import { connect } from 'react-redux';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import bind from 'bind-decorator';
import { Link } from 'react-router-dom';

import AnnotatedContent from '@Components/shared/AnnotatedContent';
import Icon from '@Components/shared/Icon';
import DisplayPublicAddress from '@Components/shared/DisplayPublicAddress';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import SocialButtons from '@Components/profile/SocialButtons';
import copy from 'copy-to-clipboard';
import Button from '@Components/form/Button';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import { ToastActions } from '@Actions/ToastActions';
import { AnimatedPlaceholderRect, AnimatedPlaceholderLine } from '@Components/placeholder/PlaceholderComponents';
import { getProfileNameOrShortPublicAddress } from '@Utils';
import millify from 'millify';
import { ReactComponent as BellPlus } from '@Images/icons/bell-plus.svg?component';
import { ReactComponent as BellChecked } from '@Images/icons/bell-checked.svg?component';
import { withTranslation } from 'react-i18next';
import AnticIcon from '@Components/shared/AnticIcon';

class ProfileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.contextDropdownRef = React.createRef();
    this.state = {
      mouseOverFollowButton: false,
      showLines: 3,
      anchorEl: null,
    };
  }

  actionButtons = () => {
    const { t } = this.props;
    let isCurrentAccount = this.props.account && this.props.account.id === this.props.activeProfileAccount?.id;

    return (
      <div className="profile-actions">
        {this.props.activeProfileAccount?.actorBlocker ? null : (
          <div className="edit-buttons">
            {isCurrentAccount || !this.props.account ? null : (
              <div
                id="ellipsis-dropdown"
                className="icons mx-2 mb-2"
                onClick={(event) => {
                  this.setState({ anchorEl: event.currentTarget }, () => {
                    this.contextDropdownRef.current.open();
                  });
                }}>
                <Icon type="fontawesome" src="fas fa-ellipsis-h" style={{ fontSize: '1.2rem' }} />
              </div>
            )}
            {!(!isCurrentAccount && this.props.activeProfileAccount?.actorFollowee) || !this.props.account ? null : this
                .props.activeProfileAccount?.actorFolloweeNotificationsEnabled ? (
              <div id="notification" className="icons ml-1 mr-2 mb-2" onClick={this.disabledNotification}>
                <BellChecked />
              </div>
            ) : (
              <div id="notification" className="icons ml-1 mr-2 mb-2" onClick={this.enabledNotification}>
                <BellPlus />
              </div>
            )}
            <div style={{ width: '120px' }}>
              {isCurrentAccount === true ? (
                <Button
                  className="button mb-2"
                  text={t('public.editProfile')}
                  type="transparent"
                  fullSize="true"
                  onClick={() =>
                    this.props.openModal('EditProfileModal', {
                      show: true,
                      backdrop: true,
                      closeOnOutsideClick: false,
                    })
                  }
                />
              ) : this.props.activeProfileAccount?.actorBlocked || this.props.activeProfileAccount?.actorBlocker ? (
                <Button
                  className="button mb-2"
                  text={this.state.mouseOverFollowButton ? t('public.unblockButton') : t('public.blockedButton')}
                  style={{ backgroundColor: '#ff0000', color: 'white' }}
                  fullSize="true"
                  disabled={this.props.loadingFollowState}
                  onMouseOver={(e) => {
                    this.setState({ mouseOverFollowButton: true });
                  }}
                  onMouseOut={(e) => {
                    this.setState({ mouseOverFollowButton: false });
                  }}
                  onClick={() => {
                    this.blockAccount(this.props.activeProfileAccount.id);
                  }}
                />
              ) : (
                <Button
                  className="button mb-2"
                  text={
                    this.props.isFetchingTokens ||
                    this.props.isFetchingProfile ||
                    this.props.loadingCurrentAccount ||
                    this.props.loadingProfileAccount
                      ? '...'
                      : this.props.activeProfileAccount?.actorFollowee
                      ? this.state.mouseOverFollowButton
                        ? t('public.unfollowButton')
                        : t('public.following')
                      : t('public.follow')
                  }
                  type={this.props.activeProfileAccount?.actorFollowee ? 'follow' : 'primary'}
                  fullSize="true"
                  disabled={
                    this.props.isFetchingTokens ||
                    this.props.isFetchingProfile ||
                    this.props.loadingCurrentAccount ||
                    this.props.loadingProfileAccount
                  }
                  onMouseOver={(e) => {
                    this.setState({ mouseOverFollowButton: true });
                  }}
                  onMouseOut={(e) => {
                    this.setState({ mouseOverFollowButton: false });
                  }}
                  onClick={() => {
                    if (!this.props.activeProfileAccount?.actorFollowee) this.followAccount();
                    else this.unfollowAccount();
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  followAccount = () => {
    const { user } = this.props;
    const authenticated = user && user.profile && user.tokens && Date.now() < user.tokens.expires_at;

    if (!authenticated) {
      this.openConnectWalletModal();
    } else if (this.props.account && this.props.account.id !== this.props.activeProfileAccount.id) {
      this.props.followAccount(this.props.activeProfileAccount.id);
    }
  };

  unfollowAccount = () => {
    const { user, activeProfileAccount } = this.props;
    const authenticated = user && user.profile && user.tokens && Date.now() < user.tokens.expires_at;

    if (!authenticated) {
      this.openConnectWalletModal();
    } else if (this.props.account && this.props.account.id !== activeProfileAccount.id) {
      this.props.openModal('VerifyUnfollowModal', {
        backdrop: true,
        closeOnOutsideClick: true,
        followerData: activeProfileAccount,
      });
    }
  };

  openConnectWalletModal = () => {
    this.props.openModal('ConnectWalletModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      callback: () => {
        this.props.closeModal();
      },
      profileAccount: this.props.profileAccount,
    });
  };

  // followAccount() {
  //   if (this.props.account && this.props.account.id !== this.props.activeProfileAccount.id) {
  //     this.props.followAccount(this.props.activeProfileAccount.id);
  //   } else {
  //     // Redirect to login with the current url as the callback url after login
  //     // or this should be done automatically if it can't do autorefresh
  //   }
  // }

  // unfollowAccount() {
  //   if (this.props.account && this.props.account.id !== this.props.activeProfileAccount.id) {
  //     this.props.openModal('VerifyUnfollowModal', {
  //       backdrop: true,
  //       closeOnOutsideClick: true,
  //       followerData: this.props.activeProfileAccount,
  //     });
  //   } else {
  //     // Redirect to login with the current url as the callback url after login
  //     // or this should be done automatically if it can't do autorefresh
  //   }
  // }

  @bind
  enabledNotification() {
    this.props.enableFolloweeNotifications(this.props.activeProfileAccount.id);
  }

  @bind
  disabledNotification() {
    this.props.disableFolloweeNotifications(this.props.activeProfileAccount.id);
  }

  muteAccount = (mutedAccountId) => {
    const { t } = this.props;

    this.props.muteAccount(mutedAccountId).then((res) => {
      if (!res.error) {
        this.props.toastSuccess(t('public.muteAccount'));
      } else {
        this.props.toastError(t('public.error'));
      }
    });
  };

  unmuteAccount = (mutedAccountId) => {
    const { t } = this.props;

    this.props.unmuteAccount(mutedAccountId).then((res) => {
      if (!res.error) {
        this.props.toastSuccess(t('public.unmuteAccount'));
      } else {
        this.props.toastError(t('public.error'));
      }
    });
  };

  copyToClipboard = () => {
    const { t } = this.props;

    if (window.location.href) {
      copy(window.location.href);
      this.props.toastSuccess(t('public.copied'));
    }
  };

  @bind
  blockAccount(blockAccountId) {
    const { t } = this.props;

    if (this.props.activeProfileAccount.actorBlocked) {
      this.props.openModal('UnblockWarningModal', {
        onOutsideClick: true,
        closeOnOutsideClick: true,
        backdrop: true,
        callback: () => {
          this.props.unblockAccount(blockAccountId).then(() => {
            this.props.toastInfo(t('public.accountUnblock'));
          });
          this.props.closeModal();
        },
        profileAccount: this.props.activeProfileAccount,
      });
    } else {
      this.props.openModal('BlockWarningModal', {
        onOutsideClick: true,
        closeOnOutsideClick: true,
        backdrop: true,
        callback: () => {
          this.props.blockAccount(blockAccountId).then(() => {
            this.props.toastInfo(t('public.accountBlocked'));
          });
          this.props.closeModal();
        },
        profileAccount: this.props.activeProfileAccount,
      });
    }
  }

  removeFollower = () => {
    const { activeProfileAccount: { id }, t } = this.props;

    if (id) {
      this.props.removeFollower(id).then(() => {
        this.props.toastSuccess(t('public.succesfullyRemoved'));
      });
    }
  }

  render() {
    // Check if the account is the current account (we will show the edit settings button if that's the case
    let isCurrentAccount = this.props.account && this.props.account.id === this.props.activeProfileAccount?.id;
    const { t } = this.props;

    return (
      <>
        <div className="profile-header">
          <div className="profile-info">
            <div className="profile-avatar-section">
              {this.props.activeProfileAccount ? (
                <>
                  <ProfileAvatar width={120} height={120} profileData={this.props.activeProfileAccount} />
                  {this.actionButtons()}
                </>
              ) : (
                <AnimatedPlaceholderRect width={120} height={120} borderRadius={60} />
              )}
            </div>

            <div className="profile-data mx-3 ml-4">
              <div className="text-big name">
                <div className="profile-name">
                  {this.props.activeProfileAccount ? (
                    getProfileNameOrShortPublicAddress(
                      this.props.activeProfileAccount?.profile.name || this.props.activeProfileAccount?.slug,
                    )
                  ) : (
                    <AnimatedPlaceholderLine width={'80%'} />
                  )}
                </div>
                {this.props.activeProfileAccount?.isAnticGroup && (
                  <AnticIcon />
                )}
                {this.props.activeProfileAccount && (
                  <DisplayPublicAddress publicAddress={this.props.activeProfileAccount?.publicAddress} />
                )}
              </div>
              <div className="text-medium slug">
                <span>
                  {this.props.activeProfileAccount ? (
                    '@' +
                    (this.props.activeProfileAccount?.profile.slug
                      ? getProfileNameOrShortPublicAddress(this.props.activeProfileAccount?.profile.slug)
                      : getProfileNameOrShortPublicAddress(this.props.activeProfileAccount?.slug))
                  ) : (
                    <AnimatedPlaceholderLine width={'40%'} />
                  )}
                </span>
                {this.props.activeProfileAccount && this.props.activeProfileAccount?.actorFollower ? (
                  <span className="actor-follow-state ml-2">{t('public.followsYou')}</span>
                ) : null}
              </div>
              {this.props.activeProfileAccount && (
                <div className="d-flex">
                  <Link to={'/' + this.props.activeProfileAccount?.slug + '/following'} className="mr-3 number">
                    {millify(this.props.activeProfileAccount?.followeesCount)}{' '}
                    <div className="text ml-1">&nbsp;{t('public.following')}</div>{' '}
                  </Link>
                  <Link to={'/' + this.props.activeProfileAccount?.slug + '/followers'} className="mr-2 number">
                    {millify(this.props.activeProfileAccount?.followersCount)}{' '}
                    <div className="text ml-1">&nbsp;{t('public.followers')}</div>{' '}
                  </Link>
                </div>
              )}
              {!(this.props.activeProfileAccount?.actorBlocked || this.props.activeProfileAccount?.actorBlocker) && (
                <>
                  <div className="user-about mt-2">
                    {this.props.activeProfileAccount && (
                      <AnnotatedContent
                        ellipsis={
                          <span>
                            ...
                            <span className="link" onClick={() => this.setState({ showLines: false })}>
                              {t('public.more')}
                            </span>
                          </span>
                        }
                        content={this.props.activeProfileAccount?.profile.about}
                        lines={this.state.showLines}
                        onTruncate={(isTruncated) => {
                          return this.state.renderShowMoreButton !== isTruncated
                            ? this.setState({ renderShowMoreButton: isTruncated })
                            : null;
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              <SocialButtons accountProfile={this.props.activeProfileAccount?.profile} />
            </div>
          </div>
          {this.actionButtons()}
        </div>
        <ContextDropdown refAnchorEl={this.state.anchorEl} ref={this.contextDropdownRef}>
          <div onClick={this.copyToClipboard}> {t('public.copyLinkProfile')} </div>
          <div
            onClick={() => {
              this.props.openModal('ShareSocialAccountModal', {
                backdrop: true,
                closeOnOutsideClick: true,
                link: '/' + this.props.activeProfileAccount.slug,
                mailBody: t('public.shareProfileMailBody'),
              });
              this.contextDropdownRef.current.close();
            }}>
            {t('public.shareProfileVia')}
          </div>
          {!isCurrentAccount && this.props.activeProfileAccount?.actorFollower && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.removeFollower();
                this.contextDropdownRef.current.close();
              }}>
              {t('public.removeButton')}  @
              {this.props.activeProfileAccount?.slug}
            </div>
          )}
          <div
            onClick={() => {
              this.props.activeProfileAccount.actorMuted
                ? this.unmuteAccount(this.props.activeProfileAccount?.id)
                : this.muteAccount(this.props.activeProfileAccount?.id);
              this.contextDropdownRef.current.close();
            }}>
            {' '}
            {this.props.activeProfileAccount?.actorMuted ? t('public.unmute') : t('public.mute')} @
            {this.props.activeProfileAccount?.slug}
          </div>
          <div
            onClick={() => {
              this.blockAccount(this.props.activeProfileAccount?.id);
              this.contextDropdownRef.current.close();
            }}>
            {' '}
            {this.props.activeProfileAccount?.actorBlocked ? t('public.unblockButton') : t('public.blockButton')} @
            {this.props.activeProfileAccount?.slug}
          </div>

          <div
            onClick={() => {
              this.props.openModal('ReportModal', {
                onOutsideClick: true,
                closeOnOutsideClick: true,
                backdrop: true,
                targetAccountId: this.props.activeProfileAccount?.id,
              });
              this.contextDropdownRef.current.close();
            }}>
            {' '}
            {t('public.report')} @{this.props.activeProfileAccount?.slug}
          </div>
        </ContextDropdown>
      </>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user.account, ...state.profile, ...state.wallet };
  },
  { ...AccountActions, ...ModalActions, ...ToastActions },
)(ProfileHeader);
export default withTranslation()(component);
