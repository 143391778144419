import * as React from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import { Link } from 'react-router-dom';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import Tooltip from '@material-ui/core/Tooltip';
import ProfileDescriberCard from '@Components/shared/ProfileDescriberCard';
import TruncateMarkup from 'react-truncate-markup';
export default class AnnotatedContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: null,
    };

    this.popoverRef = React.createRef();
  }

  componentDidMount() {
    this.setup(this.props.content);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.content !== this.props.content) {
      this.setup(this.props.content);
    }
  }

  @bind
  setup(content) {
    let blocks = [];

    const sortedAnnotations = content.annotations.sort((a, b) => a.startIndex - b.startIndex);

    let currentIndex = 0;

    sortedAnnotations.forEach((a) => {
      if (a.startIndex > currentIndex) {
        blocks.push({
          type: 'text',
          text: content.text.substring(currentIndex, a.startIndex),
        });

        currentIndex = a.startIndex;
      }

      const substring = content.text.substring(a.startIndex + 1, a.endIndex + 1);

      let link = '';
      let external = false;
      switch (a.type.toLowerCase()) {
        case 'accountmention':
          link = '/' + (a.slug != undefined ? a.slug : substring);
          break;
        case 'hashtag':
          link = '/hashtag/' + (a.hashtag != undefined ? a.hashtag : substring);
          break;
        case 'url':
          link = a.targetUrl;
          external = true;
          break;

        default:
          break;
      }

      blocks.push({
        type: 'link',
        text: content.text?.substring(a.startIndex, a.endIndex + 1),
        link,
        external,
      });

      currentIndex = a.endIndex + 1;
    });

    if (currentIndex < content.text?.length) {
      blocks.push({
        type: 'text',
        text: content.text?.substring(currentIndex, content.text.length),
      });

      currentIndex = content.text.length;
    }

    this.setState({
      blocks: blocks,
    });
  }

  render() {
    if (!this.state.blocks) return <div></div>;
    return (
      <span>
        <TruncateMarkup
          lines={this.props.lines || 999}
          onTruncate={(isTruncated) => {
            this.props.onTruncate && this.props.onTruncate(isTruncated);
          }}
          ellipsis={this.props.ellipsis || <span>...</span>}>
          <div>
            {this.state.blocks.map((b, i) => (
              <React.Fragment key={i}>
                {b.type === 'text' ? (
                  <span style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{b.text}</span>
                ) : b.type === 'link' ? (
                  b.external ? (
                    <a href={b.link} rel="noreferrer" target="_blank">
                      {b.text}
                    </a>
                  ) : b.text.startsWith('@') ? (
                    <ProfileDescriberCard slug={b.text.split('@')[1]}>
                      <Link to={b.link}>{b.text}</Link>
                    </ProfileDescriberCard>
                  ) : (
                    <Link to={b.link}>{b.text}</Link>
                  )
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </div>
        </TruncateMarkup>
      </span>
    );
  }
}

AnnotatedContent.propTypes = {
  content: PropTypes.object,
};
