import React from 'react';

import { ReactComponent as Antic } from '@Images/logo/antic-icon.svg?component';
import OiInfoTooltip from './OiInfoTooltip';
import { useTranslation } from 'react-i18next';

const AnticIcon = () => {
  const { t } = useTranslation();

  return (
    <OiInfoTooltip
        title={`${t('public.anticGroup')}`}
        placement="top">      
      <div className={`antic-icon`}>
        <Antic />
      </div>
    </OiInfoTooltip>
  );
};

export default AnticIcon;
