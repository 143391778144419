import * as React from 'react';

import ProfileAvatar from '@Components/profile/ProfileAvatar';
import Button from '@Components/form/Button';
import { withTranslation } from 'react-i18next';

class WhoToFollowCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        id: '',
        slug: '',
        profile: {
          imageUrl: '',
          name: '',
          category: {
            name: '',
          },
        },
      },
    };
  }

  componentDidMount() {
    const { account } = this.props;
    this.setState({ account: account });
  }

  getClassName() {
    let cname = 'creator-summary';
    return cname;
  }

  followAccount() {
    const { ownerAccount, followAccount } = this.props;
    const { account } = this.state;
    if (ownerAccount?.id !== account.id) {
      followAccount(account.id).then((res) => {
        this.updateAccountData(res.payload);
      });
    }
  }

  unfollowAccount() {
    const { ownerAccount, openModal } = this.props;
    const { account } = this.state;
    if (ownerAccount?.id !== account.id) {
      console.log('?');
      openModal('VerifyUnfollowModal', {
        backdrop: true,
        closeOnOutsideClick: true,
        followerData: account,
        updateAccountData: this.updateAccountData,
      });
    }
  }

  updateAccountData = (res) => {
    this.setState({ account: res });
  };

  render() {
    const { history, ownerAccount, t } = this.props;
    const {
      account,
      account: {
        slug,
        profile: {
          name,
          category: { name: categoryName },
        },
      },
      mouseOverFollowButton,
    } = this.state;

    const isCurrentAccount = ownerAccount && account && account.id === ownerAccount.id;

    return (
      <div
        className="recommended-account-card"
        onClick={() => {
          history.push(`/${slug}`);
        }}>
        <div className="img-container">
          <ProfileAvatar height={50} profileData={account} width={50} />
        </div>
        <div className="content">
          <div className="info">
              <div className="title">{name || slug}</div>
            <div className="info-category">
               <div className="category">@{slug}</div>
              <div> {account.actorFollower ? <div className="follow-back-button">{t('public.followsYou')}</div> : null}</div>
            </div>
           
          </div>
          <div>
            {!isCurrentAccount ? (
              <Button
                text={account.actorFollowee ? (mouseOverFollowButton ? t('public.unfollowButton') : t('public.following')) : t('public.follow')}
                type={account.actorFollowee ? 'follow' : 'primary'}
                onClick={(e) => {
                  e.stopPropagation();

                  if (!account.actorFollowee) this.followAccount();
                  else this.unfollowAccount();
                }}
                onMouseOut={(e) => {
                  this.setState({ mouseOverFollowButton: false });
                }}
                onMouseOver={(e) => {
                  this.setState({ mouseOverFollowButton: true });
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(WhoToFollowCard);
