import * as React from 'react';

import Button from '@Components/form/Button';
import Icon from '@Components/shared/Icon';
import millify from 'millify';
import { withTranslation } from 'react-i18next';

export class FollowHashtagsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtag: {
        tag: '',
        annotationsCount: '',
        followersCount: '',
        actorFollows: '',
      },
    };
  }

  componentDidMount() {
    const { hashtag } = this.props;
    this.setState({ hashtag: hashtag });
  }

  updateHashtag() {
    const { getHashtag } = this.props;
    const {
      hashtag: { tag },
    } = this.state;
    getHashtag(tag).then((res) => {
      this.setState({ hashtag: res.payload });
    });
  }

  followHashtag() {
    const { followHashtag } = this.props;
    const {
      hashtag: { tag },
    } = this.state;
    followHashtag(tag).then(() => {
      this.updateHashtag();
    });
  }

  unfollowHashtag() {
    const { unfollowHashtag } = this.props;
    const {
      hashtag: { tag },
    } = this.state;
    unfollowHashtag(tag).then(() => {
      this.updateHashtag();
    });
  }

  render() {
    const { history, t } = this.props;
    const {
      hashtag: { tag, followersCount, actorFollows },
      mouseOverFollowButton
    } = this.state;
    return (
      <div
        className="hashtag-card"
        onClick={() => {
          history.push(`/search/creations?q=%23${tag}`);
        }}>
        <div className="img-container">
          <div className="hashtag-container">
            <Icon src="fas fa-hashtag" type="fontawesome" />
          </div>
        </div>
        <div className="content">
          <div className="info">
            <div className="title">#{tag}</div>
            <div className="follower-count">{millify(followersCount || 0)} Followers</div>
          </div>
          <div>
            <Button
              text={actorFollows ? (mouseOverFollowButton ? t('public.unfollowButton') : t('public.following')) : t('public.follow')}
              type={actorFollows ? 'follow' : 'primary'}
              onClick={(e) => {
                e.stopPropagation();
                if (!actorFollows) this.followHashtag();
                else this.unfollowHashtag();
              }}
              onMouseOut={(e) => {
                this.setState({ mouseOverFollowButton: false });
              }}
              onMouseOver={(e) => {
                this.setState({ mouseOverFollowButton: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FollowHashtagsCard);
