import * as React from 'react';

import { AnimatedPlaceholderRect, AnimatedPlaceholderLine } from '@Components/placeholder/PlaceholderComponents';
import { Link } from 'react-router-dom';

export class Highlight extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      link: { text, to },
      children,
    } = this.props;
    return (
      <div className="highlight">
        <div className="title">{title}</div>
        {children}
        <div className="footer">
          <Link to={{ pathname: to, state: { fromOix: true } }}>{text}</Link>
        </div>
      </div>
    );
  }
}

export class PlaceholderCard extends React.Component {
  render() {
    const { borderRadius } = this.props;
    return (
      <div className="placeholder-card">
        <div className="d-flex w-100">
          <div>
            <AnimatedPlaceholderRect width={60} height={60} borderRadius={borderRadius} />
          </div>
          <div className="d-flex  w-100">
            <div className="w-100">
              <AnimatedPlaceholderLine width={'60%'} />
              <AnimatedPlaceholderLine width={'30%'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
