import * as React from 'react';
import { Helmet } from 'react-helmet';
import bind from 'bind-decorator';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Link, Route, Switch, NavLink } from 'react-router-dom';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { AccountActions, CLEAR_PROFILE, CLEAR_FOLLOWEES, CLEAR_FOLLOWERS } from '@Actions/AccountActions';
import ProfileHeader from '@Components/profile/ProfileHeader';
import { ModalActions } from '../actions/ModalActions';
import { CollectibleActions } from '../actions/CollectibleActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { ReactComponent as CollectionIcon } from '@Images/icons/collection.svg?component';
import AppRoute from '@Components/shared/AppRoute';
import { createSelector } from 'reselect';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import FollowerCard from '@Components/profile/FollowerCard';
import ProfileDescriberCard from '@Components/shared/ProfileDescriberCard';
import { InlineLoader } from '@Components/shared/Spinner';
import CollectibleCard from '@Components/shared/CollectibleCard';
import CollectionCard from '@Components/shared/CollectionCard';
import WhoToFollow from '@Components/highlight/WhoToFollow';
import FollowHashtags from '@Components/highlight/FollowHashtags';
import { getProfileNameOrShortPublicAddress } from '@Utils';
import AppScroller from '@Components/shared/AppScroller';
import millify from 'millify';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { runtimeConfig } from '../config';
import StickyBox from '@Components/shared/StickyBox';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNotFound: false,
      activeProfileId: null,
    };
  }

  componentDidMount() {
    this.loadProfileData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.username !== this.props.match.params.username) {
      this.loadProfileData();
    } else if (this.props.account && prevProps.account && this.props.account?.id !== prevProps.account?.id) {
      this.loadProfileData(true);
    }
  }

  componentWillUnmount() {
    this.props.setActiveProfileAccountId(null);
  }

  loadProfileData(forceFetch = false) {
    const { match, setActiveProfileAccountId, history, getAccountByName, recordAccountProfileView } =
      this.props;

    // enableSpinner();

    this.setState({ accountNotFound: false });
    let usernameOrAddress = forceFetch ? this.props.account?.profile?.slug : match.params.username;

    // Let's remove the at first
    if (usernameOrAddress.startsWith('@')) {
      usernameOrAddress = usernameOrAddress.substring(1);
      history.replace(usernameOrAddress);
    }

    let activeProfile = Object.values(this.props.allDetailedAccounts).find((profile) => {
      return profile.slug === usernameOrAddress || profile.publicAddress === usernameOrAddress;
    });

    if (!activeProfile || forceFetch) {
      getAccountByName(usernameOrAddress).then((result) => {
        if (!result.error) {
          setActiveProfileAccountId(result.payload.id);
          recordAccountProfileView(result.payload.id);
        } else {
          if (result.error.response.status === 404) {
            this.setState({ accountNotFound: true });
          }
        }
      });
    } else {
      setActiveProfileAccountId(activeProfile.id);
      recordAccountProfileView(activeProfile.id);
    }
  }

  render() {
    if (this.state.accountNotFound) {
      return <Redirect to="/404" />;
    }
    return (
      <>
        <Switch>
          <AppRoute
            exact
            path={['/:username', '/:username/created']}
            layout={ProfileMainLayout}
            component={Created}
            currentProfile={this.state.currentProfile}
            layoutProps={this.props}
          />
          <AppRoute path="/:username/owned" layout={ProfileMainLayout} component={Owned} layoutProps={this.props} />
          <AppRoute
            path="/:username/live-auctions"
            layout={ProfileMainLayout}
            component={LiveAuctions}
            layoutProps={this.props}
          />
          <AppRoute
            path="/:username/collections"
            layout={ProfileMainLayout}
            component={Collections}
            layoutProps={this.props}
          />
          <AppRoute path="/:username/on-sale" layout={ProfileMainLayout} component={OnSale} layoutProps={this.props} />
          <AppRoute path="/:username/saved" layout={ProfileMainLayout} component={SavedTab} layoutProps={this.props} />
          <AppRoute
            path="/:username/history"
            layout={ProfileMainLayout}
            component={HistoryTab}
            layoutProps={this.props}
          />
          <AppRoute
            path="/:username/followers"
            layout={ProfileFollowLayout}
            component={Followers}
            layoutProps={this.props}
          />
          <AppRoute
            path="/:username/following"
            layout={ProfileFollowLayout}
            component={Following}
            layoutProps={this.props}
          />
        </Switch>
      </>
    );
  }
}

class ProfileMainLayoutE extends React.Component {
  scrollbarRef = React.createRef();
  setTimeoutId = null;

  render() {
    let { allDetailedAccounts, account, activeProfileAccountId, t } = this.props;
    // We might need this for history and saved sections
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let currentProfile = account && account.id === activeProfileAccount?.id;

    const ogTitle =
      activeProfileAccount?.profile.name && activeProfileAccount?.profile.name !== ''
        ? `${activeProfileAccount?.profile.name} (@${activeProfileAccount?.slug})`
        : `@${activeProfileAccount?.slug}`;

    const profileImageUrl = activeProfileAccount?.profile.imageUrl; // We will create specific image for the og and twitter and use that

    const profileImageNoExtension = profileImageUrl?.split('.').slice(0, -1).join('.');

    let ogImageUrl = profileImageUrl;
    let ogTwitterImageUrl = profileImageUrl;
    let ogMimeType = null;

    if (profileImageNoExtension?.endsWith('_tn')) {
      ogImageUrl = profileImageNoExtension.replace('_tn', '_og.jpg');
      ogTwitterImageUrl = profileImageNoExtension.replace('_tn', '_og_twitter.jpg');
      ogMimeType = 'image/jpeg';
    }

    return (
      <div className="scrollbar-div">
        <div id="page-profile">
          <Helmet>
            <title>{ogTitle}</title>
            <meta name="description" content={activeProfileAccount?.profile.about?.text} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={activeProfileAccount?.profile.about?.text} />
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:image:secure_url" content={ogImageUrl} />
            <meta property="og:image:alt" content={'Profile Image for ' + activeProfileAccount?.slug} />
            <meta property="og:image:type" content={ogMimeType} />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:description" content={activeProfileAccount?.profile.about?.text} />
            {activeProfileAccount?.profile.twitter && (
              <meta name="twitter:creator" content={`@${activeProfileAccount.profile.twitter}`} />
            )}
            <meta name="twitter:image" content={ogTwitterImageUrl} />
            <meta name="twitter:image:alt" content={'Profile Image for ' + activeProfileAccount?.slug} />
          </Helmet>
          <div className="py-4 bg-color-2 header">
            {/* https://stackoverflow.com/questions/53883478/react-component-not-rerendering-when-props-changes */}
            <div className="content-container">
              <ProfileHeader username={activeProfileAccount?.slug} activeProfileAccount={activeProfileAccount} />
            </div>
          </div>
          <div className="bg-color-1">
            {activeProfileAccount?.actorBlocked ? (
              <div className="content-container text-center mt-4">
                <div className="border-top"></div>
                <div className="block-text">
                  <Icon className="icon" src={'fas fa-ban'} type="fontawesome" />
                  <div className="text">
                    @{activeProfileAccount?.slug} {t('public.isBlocked')}{' '}
                  </div>
                  <div className="button" onClick={() => this.props.history.push('/discover')}>
                    {t('public.discover')}
                  </div>
                </div>
              </div>
            ) : activeProfileAccount?.actorBlocker ? (
              <div className="content-container text-center mt-4">
                <div className="border-top"></div>
                <div className="block-text">
                  <Icon className="icon" src={'fas fa-ban'} type="fontawesome" />
                  <div className="text">
                    @{activeProfileAccount?.slug} {t('public.blockedYou')}{' '}
                  </div>
                  <div className="button" onClick={() => this.props.history.push('/discover')}>
                    {t('public.discover')}
                  </div>
                </div>
              </div>
            ) : activeProfileAccount?.isSuspended ? (
              <div className="content-container text-center mt-4">
                <div className="border-top"></div>
                <div className="block-text">
                  <Icon className="icon" src={'fas fa-ban'} type="fontawesome" />
                  <div className="text">
                    @{activeProfileAccount?.slug} {t('public.isSuspended')}{' '}
                  </div>
                  <div className="button" onClick={() => this.props.history.push('/discover')}>
                    {t('public.discover')}
                  </div>
                </div>
              </div>
            ) : (
              <div className="content-container">
                <div className="top-bar">
                  <div className="selectable-item my-2">
                    <NavLink
                      activeClassName={'active-tabs'}
                      className={'mx-2 tab'}
                      exact
                      to={`/${activeProfileAccount?.profile.slug}`}>
                      <Icon className="mx-1" type="fontawesome" src="fas fa-icons" />
                      <span className="option">{t('public.created')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName={'active-tabs'}
                      className={'mx-2 mr tab'}
                      to={`/${activeProfileAccount?.slug}/owned`}>
                      <Icon className="mx-1" type="fontawesome" src="fas fa-dice-d20" />

                      <span className="option">{t('public.owned')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName={'active-tabs'}
                      className={'mx-2 mr tab'}
                      to={`/${activeProfileAccount?.slug}/on-sale`}>
                      <Icon className="mx-1" type="fontawesome" src="fas fa-circle-notch" />

                      <span className="option">{t('public.onSale')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName={'active-tabs'}
                      className={'mx-2 tab'}
                      to={`/${activeProfileAccount?.slug}/live-auctions`}>
                      <Icon className="mr-1" type="fontawesome" src="fas fa-clock" />

                      <span className="option">{t('public.liveAuctions')}</span>
                    </NavLink>
                    <NavLink
                      activeClassName={'active-tabs'}
                      className={'mx-2 tab with-border'}
                      to={`/${activeProfileAccount?.slug}/collections`}>
                      <CollectionIcon className="ml-2 mr-1" width={16} height={16} />
                      <span className="option">{t('public.collections')}</span>
                    </NavLink>
                  </div>
                  {currentProfile && (
                    <div className="selectable-item my-2">
                      <NavLink
                        activeClassName={'active-tabs'}
                        className={'mr-2 pr-1 tab secret'}
                        to={`/${activeProfileAccount?.slug}/saved`}>
                        <Icon className="mr-1" src={'fas fa-bookmark'} type="fontawesome" />

                        <span className="option"> {t('public.saved')} </span>
                      </NavLink>
                      <NavLink
                        activeClassName={'active-tabs'}
                        className={'mr-1 tab secret'}
                        to={`/${activeProfileAccount?.slug}/history`}>
                        <Icon className="mr-1" src={'fas fa-history'} type="fontawesome" />

                        <span className="option"> {t('public.history')} </span>
                      </NavLink>
                    </div>
                  )}
                </div>
                <hr />
                {activeProfileAccount && this.props.children}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class _Collections extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      created: [],
      shouldFetch: true,
      isFetching: false,
      init: false,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount.createdCollections || activeProfileAccount.createdCollections?.length === 0) {
      this.getAccountCreatedCollections();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId, load } = this.props;
    let { isFetching } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (load && !isFetching) {
      this.getAccountCreatedCollections();
    }

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount.createdCollections || activeProfileAccount.createdCollections?.length === 0)
    ) {
      this.getAccountCreatedCollections();
    }
  }

  getAccountCreatedCollections = () => {
    let { getAccountCreatedCollections, allDetailedAccounts, activeProfileAccountId } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        let skip = activeProfileAccount.createdCollections ? activeProfileAccount.createdCollections.length : 0;

        getAccountCreatedCollections(
          activeProfileAccount?.id,
          `$top=${this.TOP}&$skip=${skip}&$orderby=createdAt desc`,
        ).then((res) => {
          this.setState({
            shouldFetch: res.payload?.value.length >= this.TOP,
            isFetching: false,
          });
        });
      });
    }
  };

  render() {
    let { allDetailedAccounts, activeProfileAccountId, account, allCollections, t } = this.props;
    let { isFetching, shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let currentProfile = account && account.id === activeProfileAccountId;
    let created = activeProfileAccount.createdCollections || [];
    let collectionsArray = currentProfile ? ['create-box', ...created] : created;
    let placeholderArr = currentProfile ? ['create-box', ...new Array(3).fill(0)] : new Array(4).fill(0);

    return (
      <div>
        <AppScroller
          dataLength={created?.length || 0}
          next={() => this.getAccountCreatedCollections()}
          hasMore={shouldFetch && created?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup mini className="mt-2" seeAll={false} key={'discover-group-creation'} hideCarousel hideInfo>
            {created.length > 0 || this.state.init
              ? collectionsArray.map((c, index) => {
                  if (c === 'create-box') {
                    return (
                      <div className="create-box collection" key={'creation' + index}>
                        <div className="hight-decider"></div>
                        <div className="drop-area">
                          <Link
                            className="icon-hold"
                            to={{ pathname: '/collection/create', state: { background: this.props.location } }}>
                            <div className="icon-wrap mb-2">
                              <div className="icon-white-area">
                                <Icon className="mr-1" type="fontawesome" src="fas fa-plus-circle" />
                              </div>
                            </div>
                            <p>{t('public.createNew')}</p>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  let collection = allCollections[c];
                  return collection && <CollectionCard collection={collection} key={collection?.id} />;
                })
              : shouldFetch
              ? placeholderArr.map((i) => {
                  return (
                    <div key={'placeholder' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : currentProfile
              ? placeholderArr.map((placeholder, index) => {
                  if (placeholder == 'create-box') {
                    return (
                      <div className="create-box collection" key={'creation' + index}>
                        <div className="hight-decider"></div>
                        <div className="drop-area">
                          <Link
                            className="icon-hold"
                            to={{ pathname: '/collection/create', state: { background: this.props.location } }}>
                            <div className="icon-wrap mb-2">
                              <div className="icon-white-area">
                                <Icon className="mr-1" type="fontawesome" src="fas fa-plus-circle" />
                              </div>
                            </div>
                            <p>{t('public.createNew')}</p>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  return <PlaceholderComp key={'empt-placeholdr' + index} />;
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!currentProfile && !isFetching && created && created.length === 0 ? (
          <div className="empty-text">
            <CollectionIcon width={50} height={50} />
            <div className="text">{t('public.noCollection')}</div>
            <div className="button" onClick={() => this.props.history.push('/discover')}>
              {t('public.discover')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  // render() {
  //   return (
  //     <div>
  //       <AppScroller
  //         dataLength={20}
  //         next={() => this.getAccountOwnedCollectibles()}
  //         hasMore={false}
  //         loader={<InlineLoader height={30} />}>
  //         <DiscoverCardGroup
  //           mini
  //           className="mt-2"
  //           seeAll={false}
  //           key={'discover-group-collection'}
  //           hideCarousel
  //           hideInfo>
  //           {[...Array(16)].map((_, i) => {
  //             return <CollectionCard key={i} />;
  //           })}
  //         </DiscoverCardGroup>
  //       </AppScroller>
  //     </div>
  //   );
  // }
}

class _Owned extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      owned: [],
      shouldFetch: true,
      isFetching: false,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount.owned || activeProfileAccount.owned?.length === 0) {
      this.getAccountOwnedCollectibles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId, load } = this.props;
    let { isFetching } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (load && !isFetching) {
      this.getAccountOwnedCollectibles();
    }

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount.owned || activeProfileAccount.owned?.length === 0)
    ) {
      this.getAccountOwnedCollectibles();
    }
  }

  getAccountOwnedCollectibles = () => {
    let { getAccountOwnedCollectibles, allDetailedAccounts, activeProfileAccountId } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        let skip = activeProfileAccount.owned ? activeProfileAccount.owned.length : 0;

        let ownedCollectiblesQuery = `$filter=(account/id ne ${activeProfileAccount?.id} and tokenId ne null)&$orderby=createdAt desc&$skip=${skip}&top=${this.TOP}`;

        getAccountOwnedCollectibles(activeProfileAccount?.id, ownedCollectiblesQuery).then((res) => {
          this.setState({
            shouldFetch: res.payload.value.length >= this.TOP,
            isFetching: false,
          });
        });
      });
    }
  };

  render() {
    let { allDetailedAccounts, activeProfileAccountId, account, allCollectibles, t } = this.props;
    let { shouldFetch, isFetching } = this.state;
    let currentProfile = account && account.id === activeProfileAccountId;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let collectibles = activeProfileAccount.owned;

    return (
      <div>
        <AppScroller
          dataLength={collectibles?.length || 0}
          next={() => this.getAccountOwnedCollectibles()}
          hasMore={shouldFetch && collectibles?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup
            mini
            className="mt-2"
            seeAll={false}
            key={'discover-group-collection'}
            hideCarousel
            hideInfo>
            {collectibles?.length && collectibles?.length > 0
              ? collectibles.map((c) => {
                  let collectible = allCollectibles[c];
                  return <CollectibleCard key={collectible?.id} collectible={collectible} />;
                })
              : shouldFetch
              ? new Array(4).fill(0).map((i) => {
                  return (
                    <div key={'placeholdr' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!isFetching && collectibles && collectibles.length === 0 ? (
          <div className="empty-text">
            <Icon className="mx-1" type="fontawesome" src="fas fa-dice-d20" />

            <div className="text">{currentProfile ? t('public.collectCanImagine') : t('public.noOwnedItem')}</div>
            <div className="button" onClick={() => this.props.history.push('/discover')}>
              {t('public.discover')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class _Created extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      created: [],
      shouldFetch: true,
      isFetching: false,
      init: false,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount.created || activeProfileAccount.created?.length === 0) {
      this.getAccountCreatedCollectibles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId, load } = this.props;
    let { isFetching } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (load && !isFetching) {
      this.getAccountCreatedCollectibles();
    }

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount.created || activeProfileAccount.created?.length === 0)
    ) {
      this.getAccountCreatedCollectibles();
    }
  }

  getAccountCreatedCollectibles = () => {
    let { getAccountCreatedCollectibles, allDetailedAccounts, activeProfileAccountId } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        let skip = activeProfileAccount.created ? activeProfileAccount.created.length : 0;

        getAccountCreatedCollectibles(
          activeProfileAccount?.id,
          `$top=${this.TOP}&$skip=${skip}&$orderby=createdAt desc`,
        ).then((res) => {
          this.setState({
            shouldFetch: res.payload.value.length >= this.TOP,
            isFetching: false,
          });
        });
      });
    }
  };

  render() {
    let { allDetailedAccounts, activeProfileAccountId, account, allCollectibles, t } = this.props;
    let { isFetching, shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let currentProfile = account && account.id === activeProfileAccountId;
    let created = activeProfileAccount.created || [];
    let collectiblesArr = currentProfile ? ['create-box', ...created] : created;
    let placeholderArr = currentProfile ? ['create-box', ...new Array(3).fill(0)] : new Array(4).fill(0);

    return (
      <div>
        <AppScroller
          dataLength={created?.length || 0}
          next={() => this.getAccountCreatedCollectibles()}
          hasMore={shouldFetch && created?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup mini className="mt-2" seeAll={false} key={'discover-group-creation'} hideCarousel hideInfo>
            {created.length > 0 || this.state.init
              ? collectiblesArr.map((c, index) => {
                  if (c === 'create-box') {
                    return (
                      <div className="create-box" key={'creation' + index}>
                        <div className="hight-decider"></div>
                        <div className="drop-area">
                          <Link
                            className="icon-hold"
                            to={{ pathname: '/collectible/upload', state: { background: this.props.location } }}>
                            <div className="icon-wrap mb-2">
                              <div className="icon-white-area">
                                <Icon className="mr-1" type="fontawesome" src="fas fa-plus-circle" />
                              </div>
                            </div>
                            <p> {t('public.createNew')} </p>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  let collectible = allCollectibles[c];
                  return <CollectibleCard key={collectible.id} collectible={collectible} />;
                })
              : shouldFetch
              ? placeholderArr.map((i) => {
                  return (
                    <div key={'placeholder' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : currentProfile
              ? placeholderArr.map((placeholder, index) => {
                  if (placeholder == 'create-box') {
                    return (
                      <div className="create-box" key={'creation' + index}>
                        <div className="hight-decider"></div>
                        <div className="drop-area">
                          <Link
                            className="icon-hold"
                            to={{ pathname: '/collectible/upload', state: { background: this.props.location } }}>
                            <div className="icon-wrap mb-2">
                              <div className="icon-white-area">
                                <Icon className="mr-1" type="fontawesome" src="fas fa-plus-circle" />
                              </div>
                            </div>
                            <p> {t('public.createNew')} </p>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  return <PlaceholderComp key={'empt-placeholdr' + index} />;
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!currentProfile && !isFetching && created && created.length === 0 ? (
          <div className="empty-text">
            <Icon className="icon" src={'fas fa-icons'} type="fontawesome" />
            <div className="text"> {t('public.noCreation')} </div>
            <div className="button" onClick={() => this.props.history.push('/discover')}>
              {t('public.discover')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class _OnSale extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      owned: [],
      shouldFetch: true,
      isFetching: false,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount.onSale || activeProfileAccount.onSale?.length === 0) {
      this.getAccountOnSaleCollectibles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId, load } = this.props;
    let { isFetching } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (load && !isFetching) {
      this.getAccountOnSaleCollectibles();
    }

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount.onSale || activeProfileAccount.onSale?.length === 0)
    ) {
      this.getAccountOnSaleCollectibles();
    }
  }

  getAccountOnSaleCollectibles = () => {
    let { getAccountOnSaleCollectibles, allDetailedAccounts, activeProfileAccountId } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        let skip = activeProfileAccount.onSale ? activeProfileAccount.onSale.length : 0;

        let onSaleCollectiblesQuery = `$filter=(mostRecentListing/status eq 'Open' and mostRecentListing/type eq 'FixedPrice')&$orderby=createdAt desc&$skip=${skip}&top=${this.TOP}`;

        getAccountOnSaleCollectibles(activeProfileAccount?.id, onSaleCollectiblesQuery).then((res) => {
          this.setState({
            shouldFetch: res.payload.value.length >= this.TOP,
            isFetching: false,
          });
        });
      });
    }
  };

  render() {
    let { allDetailedAccounts, activeProfileAccountId, account, allCollectibles, t } = this.props;
    let { shouldFetch, isFetching } = this.state;
    let currentProfile = account && account.id === activeProfileAccountId;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let collectibles = activeProfileAccount.onSale;

    return (
      <div>
        <AppScroller
          dataLength={collectibles?.length || 0}
          next={() => this.getAccountOnSaleCollectibles()}
          hasMore={shouldFetch && collectibles?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup
            mini
            className="mt-2"
            seeAll={false}
            key={'discover-group-collection'}
            hideCarousel
            hideInfo>
            {collectibles?.length && collectibles?.length > 0
              ? collectibles.map((c) => {
                  let collectible = allCollectibles[c];
                  return <CollectibleCard key={collectible.id} collectible={collectible} />;
                })
              : shouldFetch
              ? new Array(4).fill(0).map((i) => {
                  return (
                    <div key={'placeholdr' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!isFetching && collectibles && collectibles.length === 0 ? (
          <div className="empty-text">
            <Icon className="icon" src={'fas fa-circle-notch'} type="fontawesome" />
            <div className="text">{currentProfile ? t('public.onSaleAppearHere') : t('public.noOnSaleItem')}</div>
            <div className="button" onClick={() => this.props.history.push('/discover')}>
              {t('public.discover')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class _LiveAuctions extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      created: [],
      shouldFetch: true,
      isFetching: false,
      init: false,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount.liveAuctions || activeProfileAccount.liveAuctions?.length === 0) {
      this.getAccountLiveAuctionCollectibles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId, load } = this.props;
    let { isFetching } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (load && !isFetching) {
      this.getAccountLiveAuctionCollectibles();
    }

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount.liveAuctions || activeProfileAccount.liveAuctions?.length === 0)
    ) {
      this.getAccountLiveAuctionCollectibles();
    }
  }

  getAccountLiveAuctionCollectibles = () => {
    let { getAccountReserveAuctionCollectibles, allDetailedAccounts, activeProfileAccountId } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        const skip = activeProfileAccount.liveAuctions ? activeProfileAccount.liveAuctions.length : 0;

        const liveAuctionCollectiblesFilter = `(mostRecentListing/status eq 'Open' and mostRecentListing/type eq 'ReserveAuction' and (mostRecentListing/bidCount eq 0 or mostRecentListing/dateAuctionEnding gt ${moment().toISOString()}))`;
        const liveAuctionCollectiblesQuery = `$filter=${liveAuctionCollectiblesFilter}&$orderby=createdAt desc&$skip=${skip}&top=${this.TOP}`;

        // We need to filter out the timers as well!

        getAccountReserveAuctionCollectibles(activeProfileAccount?.id, liveAuctionCollectiblesQuery).then((res) => {
          this.setState({
            shouldFetch: res.payload.value.length >= this.TOP,
            isFetching: false,
          });
        });
      });
    }
  };

  render() {
    let { allDetailedAccounts, activeProfileAccountId, account, allCollectibles, t } = this.props;
    let { shouldFetch, isFetching } = this.state;
    let currentProfile = account && account.id === activeProfileAccountId;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    let collectibles = activeProfileAccount.liveAuctions;

    return (
      <div>
        <AppScroller
          dataLength={collectibles?.length || 0}
          next={() => this.getAccountOnSaleCollectibles()}
          hasMore={shouldFetch && collectibles?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup
            mini
            className="mt-2"
            seeAll={false}
            key={'discover-group-collection'}
            hideCarousel
            hideInfo>
            {collectibles?.length && collectibles?.length > 0
              ? collectibles.map((c) => {
                  let collectible = allCollectibles[c];
                  return <CollectibleCard key={collectible.id} collectible={collectible} />;
                })
              : shouldFetch
              ? new Array(4).fill(0).map((i) => {
                  return (
                    <div key={'placeholdr' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!isFetching && collectibles && collectibles.length === 0 ? (
          <div className="empty-text">
            <Icon className="icon" src={'fas fa-clock'} type="fontawesome" />
            <div className="text">
              {currentProfile ? t('public.liveAuctionsAppearHere') : t('public.noLiveAuction')}
            </div>
            <div className="button" onClick={() => this.props.history.push('/discover')}>
              {t('public.discover')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class _SavedTab extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      shouldFetch: true,
      isFetching: false,
      init: false,
    };
  }

  componentDidMount() {
    let { bookmarkCollectibles } = this.props;

    if (bookmarkCollectibles.length === 0) {
      this.getAccountBookmarks();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { load } = this.props;
    let { isFetching } = this.state;

    if (load && !isFetching) {
      this.getAccountBookmarks();
    }
  }

  getAccountBookmarks = () => {
    let { getBookmarks, bookmarkCollectibles } = this.props;
    let { shouldFetch } = this.state;

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        getBookmarks(`$expand=Post&$top=${this.TOP}&$skip=${bookmarkCollectibles.length}&$orderby=createdAt desc`).then(
          (res) => {
            this.setState({
              shouldFetch: res.payload.value.length >= this.TOP,
              isFetching: false,
              init: true,
            });
          },
        );
      });
    }
  };

  render() {
    let { bookmarkCollectibles, t } = this.props;
    let { shouldFetch, isFetching } = this.state;

    return (
      <div>
        <AppScroller
          dataLength={bookmarkCollectibles?.length || 0}
          next={() => this.getAccountBookmarks()}
          hasMore={shouldFetch && bookmarkCollectibles?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup mini className="mt-2" seeAll={false} key={'discover-group-saved'} hideCarousel hideInfo>
            {bookmarkCollectibles?.length && bookmarkCollectibles?.length > 0
              ? bookmarkCollectibles?.map((collectible) => {
                  return <CollectibleCard key={collectible.id} collectible={collectible.post} />;
                })
              : shouldFetch
              ? new Array(4).fill(0).map((i) => {
                  return (
                    <div key={'placeholdr' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!isFetching && bookmarkCollectibles && bookmarkCollectibles?.length === 0 ? (
          <div className="empty-text">
            <Icon className="icon" src={'fas fa-bookmark'} type="fontawesome" />
            <div className="text"> {t('public.saveSectionText')} </div>
            <div className="button" onClick={() => this.props.history.push('/feed')}>
              {t('public.goToFeed')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class _HistoryTab extends React.Component {
  TOP = 32;

  constructor(props) {
    super(props);

    this.state = {
      shouldFetch: true,
      isFetching: false,
      init: false,
    };
  }

  componentDidMount() {
    let { historyCollectibles } = this.props;

    if (historyCollectibles.length === 0) {
      this.getAccountHistory();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { load } = this.state;
    let { isFetching } = this.props;

    if (load && !isFetching) {
      this.getAccountHistory();
    }
  }

  getAccountHistory = () => {
    let { getHistory, historyCollectibles } = this.props;
    let { shouldFetch } = this.state;

    if (shouldFetch) {
      this.setState({ isFetching: true }, () => {
        getHistory(
          `$expand=TargetPost&$top=${this.TOP}&$skip=${historyCollectibles.length}&$orderby=viewedAt desc`,
        ).then((res) => {
          this.setState({
            shouldFetch: res.payload.value.length >= this.TOP,
            isFetching: false,
            init: true,
          });
        });
      });
    }
  };

  render() {
    let { historyCollectibles, t } = this.props;
    let { isFetching, shouldFetch } = this.state;

    return (
      <div>
        <AppScroller
          dataLength={historyCollectibles?.length || 0}
          next={() => this.getAccountHistory()}
          hasMore={shouldFetch && historyCollectibles?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          <DiscoverCardGroup mini className="mt-2" seeAll={false} key={'discover-group-history'} hideCarousel hideInfo>
            {historyCollectibles?.length && historyCollectibles?.length > 0
              ? historyCollectibles?.map((collectible) => {
                  return <CollectibleCard key={collectible.id} collectible={collectible.targetPost} />;
                })
              : shouldFetch
              ? new Array(4).fill(0).map((i) => {
                  return (
                    <div key={'placeholdr' + i}>
                      <CollectibleCard />
                    </div>
                  );
                })
              : null}
          </DiscoverCardGroup>
        </AppScroller>
        {!isFetching && historyCollectibles && historyCollectibles?.length === 0 ? (
          <div className="empty-text">
            <Icon className="icon" src={'fas fa-history'} type="fontawesome" />
            <div className="text">{t('public.historyEmpty')}</div>
            <div className="button" onClick={() => this.props.history.push('/feed')}>
              {t('public.goToFeed')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class ProfileFollowLayoutE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreTrending: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  init() {
    this._isMounted = true;
  }

  render() {
    let { allDetailedAccounts, activeProfileAccountId, t } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount) return <div></div>;

    return (
      <div id="page-followers">
        <Helmet>
          <title>{activeProfileAccount.profile.name}</title>
        </Helmet>
        <div className="content-container my-3">
          <>
            <div className="main-content mr-2">
              <div className="back">
                <div className="icon-name">
                  <Link className="icon" to={`/${activeProfileAccount.slug}`}>
                    <Icon type="fontawesome" src="fas fa-arrow-left" />
                  </Link>
                  <div className="ml-2">
                    <div className="name">
                      {getProfileNameOrShortPublicAddress(
                        activeProfileAccount.profile.name || activeProfileAccount.slug,
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="options">
                <NavLink activeClassName="is-active" to={`/${activeProfileAccount.slug}/following`}>
                  {millify(activeProfileAccount.followeesCount)} {t('public.following')}
                </NavLink>
                <NavLink activeClassName="is-active" to={`/${activeProfileAccount.slug}/followers`}>
                  {millify(activeProfileAccount.followersCount)} {t('public.followers')}
                </NavLink>
              </div>
              <hr className="my-2" />
              {this.props.children}
            </div>

            <StickyBox offsetBottom={20} offsetTop={90}>
              <div className="side-content">
                <WhoToFollow />
                <div className="mt-3">
                  <FollowHashtags />
                </div>
              </div>
            </StickyBox>
          </>
        </div>
      </div>
    );
  }
}

class FollowersE extends React.Component {
  TOP = 32;
  constructor(props) {
    super(props);

    this.state = {
      shouldFetch: true,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount['followers'] || activeProfileAccount['followers']?.length === 0) {
      this.fetchFollowers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount['followers'] || activeProfileAccount['followers']?.length === 0)
    ) {
      this.fetchFollowers();
    }
  }

  fetchFollowers() {
    let { allDetailedAccounts, activeProfileAccountId, getAccountFollowers } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    const skip = activeProfileAccount['followers']?.length || 0;

    let query = '$expand=Profile&$skip=' + skip + '&$top=' + this.TOP;
    getAccountFollowers(activeProfileAccount?.id, query).then((result) => {
      this.setState({ shouldFetch: result?.payload?.value?.length >= this.TOP });
    });
  }

  render() {
    let { allDetailedAccounts, activeProfileAccountId, allAccounts } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    return (
      <>
        {activeProfileAccount['followers']?.length > 0 ? (
          <AppScroller
            dataLength={activeProfileAccount['followers']?.length || 0}
            next={() => this.fetchFollowers()}
            hasMore={shouldFetch && activeProfileAccount['followers']?.length >= this.TOP}
            loader={<InlineLoader height={30} />}>
            <>
              {activeProfileAccount['followers']?.map((followerId, index) => {
                let follower = allAccounts[followerId];
                return (
                  <ProfileDescriberCard key={index} slug={follower.slug} placement="right">
                    <div>
                      <FollowerCard followerData={follower} key={index} className="my-1" />
                    </div>
                  </ProfileDescriberCard>
                );
              })}
            </>
          </AppScroller>
        ) : null}
      </>
    );
  }
}

class FollowingE extends React.Component {
  TOP = 32;
  constructor(props) {
    super(props);

    this.state = {
      fetchMore: true,
    };
  }

  componentDidMount() {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (!activeProfileAccount['followees'] || activeProfileAccount['followees']?.length === 0) {
      this.fetchFollowees();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { allDetailedAccounts, activeProfileAccountId } = this.props;

    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    if (
      prevProps.activeProfileAccountId !== activeProfileAccountId &&
      (!activeProfileAccount['followees'] || activeProfileAccount['followees']?.length === 0)
    ) {
      this.fetchFollowees();
    }
  }

  fetchFollowees() {
    let { allDetailedAccounts, activeProfileAccountId, getAccountFollowees } = this.props;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];
    const skip = activeProfileAccount['followees']?.length || 0;

    let query = '$expand=Profile&$skip=' + skip + '&$top=' + this.TOP;
    getAccountFollowees(activeProfileAccount?.id, query).then((result) => {
      this.setState({ fetchMore: result?.payload?.value?.length >= this.TOP });
    });
  }

  render() {
    let { allDetailedAccounts, activeProfileAccountId, allAccounts } = this.props;
    let { shouldFetch } = this.state;
    let activeProfileAccount = allDetailedAccounts[activeProfileAccountId];

    return (
      <>
        <AppScroller
          dataLength={activeProfileAccount['followers']?.length || 0}
          next={() => this.fetchFollowers()}
          hasMore={shouldFetch && activeProfileAccount['followees']?.length >= this.TOP}
          loader={<InlineLoader height={30} />}>
          {activeProfileAccount['followees']?.length > 0 ? (
            <>
              {activeProfileAccount['followees']?.map((followeeId, index) => {
                let followee = allAccounts[followeeId];
                return (
                  <ProfileDescriberCard key={index} slug={followee.slug} placement="right">
                    <div>
                      <FollowerCard followerData={followee} key={index} className="my-1" />
                    </div>
                  </ProfileDescriberCard>
                );
              })}
            </>
          ) : null}
        </AppScroller>
      </>
    );
  }
}

const PlaceholderComp = () => {
  return (
    <div className="empty-placeholder">
      <div className="height-decider"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.profile,
  ...state.service,
  ...state.search,
  ...state.discover,
  ...state.cache,
});

const mapDispatchToProps = {
  ...AccountActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
};

const Owned = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_Owned));
const Collections = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_Collections));
const SavedTab = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_SavedTab));
const HistoryTab = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_HistoryTab));
const Created = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_Created));
const OnSale = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_OnSale));
const LiveAuctions = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(_LiveAuctions));
const Followers = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(FollowersE));
const Following = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(FollowingE));
const ProfileFollowLayout = connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...SpinnerActions }, null, {
  forwardRef: true,
})(withTranslation()(ProfileFollowLayoutE));
const ProfileMainLayout = connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...SpinnerActions }, null, {
  forwardRef: true,
})(withTranslation(null, { withRef: true })(ProfileMainLayoutE));
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation()(ProfilePage));
